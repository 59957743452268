
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { date } from "yup";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface repas {
  date: string;
  createdAt: string;
  repeat: {
    endDate: string;
    days: Array<any>;
  };
}
export default defineComponent({
  name: "Cantine",
  components: {},

  setup() {
    const store = useStore();

    const { t } = useI18n();
    const repeat = [];
    const dataa = ref<repas[]>();
    const selectedValue = ref(moment().format("DD/MM/YYYY"));
    onMounted(() => {
      setCurrentPageTitle("dashboard.canteen");
    });
    ApiService.setHeader();
    ApiService.post("/cantine/filter", { query: {} })
      .then(({ data }) => {
        console.log(data);
        dataa.value = data;
        console.log(dataa.value);
      })
      .catch((e) => console.log(e));
    const filtredData = computed(() => {
      let repeats = dataa?.value?.filter((food) => {
        return (
          moment(food.date).format("YYYYDDMM") ==
            moment(selectedValue.value).format("YYYYDDMM") ||
          (food.repeat &&
            moment(selectedValue.value).isSameOrBefore(
              moment(food.repeat.endDate),
              "day"
            ) &&
            moment(selectedValue.value).isSameOrAfter(
              moment(food.createdAt),
              "day"
            ) &&
            food.repeat.days.includes(moment(selectedValue.value).day()))
        );
      });

      //let getAllFiltered = repeats?.concat(data);
      console.log("filterd:", new Set(repeats));
      return new Set(repeats);
    });
    const getUrl = (item) => {
      let url =
        '"' +
        store.getters.serverConfigUrl.base_url +
        "/" +
        item.file.replaceAll("\\", "/") +
        '"';
      return url;
    };
    return {
      t,
      getUrl,
      dataa,
      selectedValue,
      filtredData,
      store,
    };
  },
});
